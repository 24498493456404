import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import dayjs from "dayjs";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getGameHistory,
  resetGameCoin,
  rouletteCasinoHistory,
} from "../../store/GameHistory/action";
import TeenPatti from "./gameHistory/TeenPatti";
import FerryWheel from "./gameHistory/FerryWheel";
import RouletteCasino from "./gameHistory/RouletteCasino";
import { io } from "socket.io-client";
import { UNSET_ADMIN } from "../../store/admin/types";
import { baseURL } from "../../util/Config";
import FruitLoop from "./gameHistory/FruiteLoop";

function GameHistory  ()  {
  const [type, setType] = useState("TeenPatti");

  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const ipAddress = localStorage.getItem("ipAddress");


  // useEffect(() => {
  //   if (ipAddress.length > 0) {
  //     const socket = io.connect(baseURL, {
  //       transports: ["websocket", "polling", "flashsocket"],
  //       query: {
  //         adminRoom: admin && admin?._id,
  //       },
  //     });
  //     socketRef.current = socket ? socket : null;
  //     socketRef.current.on("connect", () => {
  //       console.log("Socket connected");
  //       // socketRef.current.emit("ticket", ipAddress && ipAddress);
  //     });
  //     socketRef.current.on("checkIpAdress", (data) => {
  //       console.log("data",data);
  //       if (data !== ipAddress) {
  //         dispatch({ type: UNSET_ADMIN });
  //       }
  //     });
  //     socketRef.current.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });
  //     socketRef.current.on("connect_error", (error) => {
  //       console.error("Socket connection error:", error);
  //     });
  //     return () => {
  //       console.log("Cleaning up socket connection");
  //       socketRef.current.disconnect();
  //     };
  //   }
  // }, [ipAddress]);

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Game History</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Game History
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="my-2">
              <button
                type="button"
                className={`btn btn-sm ${
                  type === "TeenPatti" ? "btn-info" : "disabledBtn"
                }`}
                onClick={() => setType("TeenPatti")}
              >
                <span className="">Teen Patti</span>
              </button>
              <button
                type="button"
                className={`btn btn-sm ${
                  type === "RouletteCasino" ? "btn-danger" : "disabledBtn"
                } ms-3`}
                onClick={() => setType("RouletteCasino")}
              >
                <span className="">Roulette Casino</span>
              </button>
              <button
                type="button"
                className={`btn btn-sm ${
                  type === "ferryWheel" ? "btn-success" : "disabledBtn"
                } ms-3`}
                onClick={() => setType("ferryWheel")}
              >
                <span className="">Ferry Wheel</span>
              </button>

              <button
                type="button"
                className={`btn btn-sm ${
                  type === "fruitLoop" ? "btn-success" : "disabledBtn"
                } ms-3`}
                onClick={() => setType("fruitLoop")}
              >
                <span className="">Fruite Loop</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {type === "TeenPatti" ? (
        <TeenPatti />
      ) : type === "ferryWheel" ? (
        <FerryWheel />
      ) : type === "RouletteCasino" ? (
        <RouletteCasino />
      ) : (
        <FruitLoop/>
      )}
      {/* {type === "TeenPatti" && (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header pb-0">
                <div className="row my-3">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                    <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                      <button
                        className="btn btn-light text-info"
                        style={{ marginRight: 5 }}
                        onClick={handleResetCoin}
                      >
                        Reset Diamond
                      </button>
                      <button
                        className="btn btn-info"
                        style={{ marginRight: 5 }}
                        onClick={getAllUser}
                      >
                        All
                      </button>
                      <button
                        className="collapsed btn btn-info ml-5"
                        value="check"
                        data-toggle="collapse"
                        data-target="#datePicker"
                        onClick={collapsedDatePicker}
                      >
                        Analytics
                        <ExpandMoreIcon />
                      </button>
                      <p style={{ paddingLeft: 10 }} className="my-2 ">
                        {sDate !== "ALL" && sDate + " to " + eDate}
                      </p>
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                    <div className="d-flex justify-content-end mt-3">
                      <span className="text-danger">
                        Admin Total Diamonds :
                        <span className="text-info">
                          &nbsp;&nbsp;{adminCoin}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div
                    id="datePicker"
                    className="collapse mt-5 pt-5"
                    aria-expanded="false"
                  >
                    <div className="container table-responsive">
                      <div key={JSON.stringify(date)}>
                        <DateRangePicker
                          onChange={(item) => {
                            setDate([item.selection]);
                            const dayStart = dayjs(
                              item.selection.startDate
                            ).format("YYYY-MM-DD");
                            const dayEnd = dayjs(item.selection.endDate).format(
                              "YYYY-MM-DD"
                            );
                            setActivePage(1);
                            setsDate(dayStart);
                            seteDate(dayEnd);
                            props.getGameHistory(
                              activePage,
                              rowsPerPage,
                              sDate,
                              eDate
                            );
                          }}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          ranges={date}
                          direction="horizontal"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body card-overflow">
                <table className="table" style={{ borderCollapse: "collapse" }}>
                  <thead className="text-center">
                    <tr>
                      <th>No</th>
                    
                      <th>Admin Diamond</th>
                      <th>Win/Lose</th>
                      <th>winner Diamond Minus</th>
                      <th>Total Add Diamond</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {data?.length > 0 ? (
                      data?.map((data, index) => {
                        var gameDate = data?.date ? data?.date.split(",") : [];

                        return (
                          <>
                            <tr
                              data-toggle="collapse"
                              data-target={`#demo${index}`}
                              className="accordion-toggle pointer-cursor"
                            >
                              <td>{index + 1}</td>

                             
                              <td className="text-success">
                                {data?.updatedAdminCoin}
                              </td>
                              <td
                                className={
                                  data?.totalAdd + data?.winnerCoinMinus > 0
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {data?.totalAdd + data?.winnerCoinMinus > 0
                                  ? data?.totalAdd + data?.winnerCoinMinus
                                  : Math.abs(
                                      data?.totalAdd + data?.winnerCoinMinus
                                    )}
                              </td>
                              <td className="text-danger">
                                {parseInt(data?.winnerCoinMinus)}
                              </td>
                              <td className="text-warning">{data?.totalAdd}</td>

                              <td className="text-info">{gameDate[0]}</td>
                              <td className="text-primary">{gameDate[1]}</td>

                              <td className="pointer-cursor">
                                <i className="fas fa-info-circle fa-lg"></i>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="8" className="hiddenRow">
                                <div
                                  id={`demo${index}`}
                                  className="accordian-body collapse"
                                >
                                  <h6 className="text-danger">Frame History</h6>
                                  <table className="w-100">
                                    <thead className="text-center ">
                                      <tr className="">
                                        <th>No</th>
                                        <th>select Frame</th>
                                        <th>bit</th>
                                        <th>card</th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      className="text-center"
                                      style={{
                                        maxHeight: 100,
                                        overflowY: "auto",
                                      }}
                                    >
                                      {data?.cardCoin?.length > 0 ? (
                                        data?.cardCoin.map((report, no) => {
                                          return (
                                            <tr
                                              key={no}
                                              className="border-top "
                                            >
                                              <td>{no + 1}</td>
                                              <td
                                                className={`${
                                                  report?.winner
                                                    ? "text-success"
                                                    : "text-danger"
                                                } fw-bold`}
                                              >
                                                {report?.selectFrame}
                                              </td>

                                              <td className="text-warning">
                                                {report?.bit}
                                              </td>
                                              <td className="text-info">
                                                {report?.card}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td colSpan="7" align="center">
                                            Nothing to show!!
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" align="center">
                          Nothing to show!!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <Pagination
                  activePage={activePage}
                  rowsPerPage={rowsPerPage}
                  userTotal={total}
                  handleRowsPerPage={handleRowsPerPage}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default GameHistory;

// {type === "RouletteCasino" && (
//   <div className="row">
//     <div className="col">
//       <div className="card">
//         <div className="card-header pb-0">
//           <div className="row my-3">
//             <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//               <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
//                 <button
//                   className="btn btn-light text-info"
//                   style={{ marginRight: 5 }}
//                   onClick={handleResetCoin}
//                 >
//                   Reset Diamond
//                 </button>
//                 <button
//                   className="btn btn-info"
//                   style={{ marginRight: 5 }}
//                   onClick={getAllUser}
//                 >
//                   All
//                 </button>
//                 <button
//                   className="collapsed btn btn-info ml-5"
//                   value="check"
//                   data-toggle="collapse"
//                   data-target="#datePicker"
//                   onClick={collapsedDatePicker}
//                 >
//                   Analytics
//                   <ExpandMoreIcon />
//                 </button>
//                 <p style={{ paddingLeft: 10 }} className="my-2 ">
//                   {sDate !== "ALL" && sDate + " to " + eDate}
//                 </p>
//               </div>
//             </div>

//             <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
//               <div className="d-flex justify-content-end mt-3">
//                 <span className="text-danger">
//                   Admin Total Diamonds :
//                   <span className="text-info">
//                     &nbsp;&nbsp;{adminCoin}
//                   </span>
//                 </span>
//               </div>
//             </div>
//             <div
//               id="datePicker"
//               className="collapse mt-5 pt-5"
//               aria-expanded="false"
//             >
//               <div className="container table-responsive">
//                 <div key={JSON.stringify(date)}>
//                   <DateRangePicker
//                     onChange={(item) => {
//                       setDate([item.selection]);
//                       const dayStart = dayjs(
//                         item.selection.startDate
//                       ).format("YYYY-MM-DD");
//                       const dayEnd = dayjs(item.selection.endDate).format(
//                         "YYYY-MM-DD"
//                       );
//                       setActivePage(1);
//                       setsDate(dayStart);
//                       seteDate(dayEnd);
//                       props.getGameHistory(
//                         activePage,
//                         rowsPerPage,
//                         sDate,
//                         eDate
//                       );
//                     }}
//                     showSelectionPreview={true}
//                     moveRangeOnFirstSelection={false}
//                     ranges={date}
//                     direction="horizontal"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="card-body card-overflow">
//           <table className="table" style={{ borderCollapse: "collapse" }}>
//             <thead className="text-center">
//               <tr>
//                 <th>No</th>
//                 {/* <th>winner Card</th> */}
//                 <th>Admin Diamond</th>
//                 <th>Win/Lose</th>
//                 <th>winner Diamond Minus</th>
//                 <th>Total Add Diamond</th>
//                 <th>Date</th>
//                 <th>Time</th>
//               </tr>
//             </thead>
//             <tbody className="text-center">
//               {data?.length > 0 ? (
//                 data?.map((data, index) => {
//                   var gameDate = data?.date ? data?.date.split(",") : [];

//                   return (
//                     <>
//                       <tr
//                         data-toggle="collapse"
//                         data-target={`#demo${index}`}
//                         className="accordion-toggle pointer-cursor"
//                       >
//                         <td>{index + 1}</td>

//                         {/* <td>{data?.winnerIndex}</td> */}
//                         <td className="text-success">
//                           {data?.updatedAdminCoin}
//                         </td>
//                         <td
//                           className={
//                             data?.totalAdd + data?.winnerCoinMinus > 0
//                               ? "text-success"
//                               : "text-danger"
//                           }
//                         >
//                           {data?.totalAdd + data?.winnerCoinMinus > 0
//                             ? data?.totalAdd + data?.winnerCoinMinus
//                             : Math.abs(
//                                 data?.totalAdd + data?.winnerCoinMinus
//                               )}
//                         </td>
//                         <td className="text-danger">
//                           {parseInt(data?.winnerCoinMinus)}
//                         </td>
//                         <td className="text-warning">{data?.totalAdd}</td>

//                         <td className="text-info">{gameDate[0]}</td>
//                         <td className="text-primary">{gameDate[1]}</td>
//                       </tr>
//                     </>
//                   );
//                 })
//               ) : (
//                 <tr>
//                   <td colSpan="7" align="center">
//                     Nothing to show!!
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//           <Pagination
//             activePage={activePage}
//             rowsPerPage={rowsPerPage}
//             userTotal={total}
//             handleRowsPerPage={handleRowsPerPage}
//             handlePageChange={handlePageChange}
//           />
//         </div>
//       </div>
//     </div>
//   </div>
// )}
