import React, { useEffect, useRef } from "react";

// js
import "../assets/js/main.min.js";

//router
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

// component
import Navbar from "../component/navbar/Navbar";
import Topnav from "../component/navbar/Topnav";
import BannerTable from "../component/table/Banner";
import CoinPlanTable from "../component/table/CoinPlan";
import PurchaseCoinPlanHistoryTable from "../component/table/PurchaseCoinPlanHistory";
import VIPPlanTable from "../component/table/VIPPlan";
import PurchaseVIPPlanTable from "../component/table/PurchaseVipPlanHistory";
import GiftCategoryTable from "../component/table/GiftCategory";
import GiftTable from "../component/table/Gift";
import SongTable from "../component/table/Song";
import SongDialog from "../component/dialog/Song";
import GameTable from "../component/table/Game";
import GiftDialog from "../component/dialog/Gift/Add";
import HashtagTable from "../component/table/Hashtag";
import LevelTable from "../component/table/Level";
import UserTable from "../component/table/User";
import PostTable from "../component/table/Post";
import VideoTable from "../component/table/Video";
import UserDetail from "./UserDetail";
import UserHistory from "./UserHistory";
import PostDetail from "./PostDetail";
import VideoDetail from "./VideoDetail";
import Dashboard from "./Dashboard";
import Setting from "./Settings";
import ThemeTable from "../component/table/Theme.js";
import Advertisement from "../component/table/Advertisement";
import PendingComplainTable from "../component/table/PendingComplain";
import SolvedComplainTable from "../component/table/SolvedComplain";
import ReportedUserTable from "../component/table/ReportedUser";
import StickerTable from "../component/table/Sticker";

import LiveUserTable from "../component/table/LiveUserTable.js";
import AgencyTable from "../component/table/AgencyTable.js";
import AgencyWiseHost from "./AgencyWiseHost.js";
import AgencyRedeemRequest from "../component/table/agencyRedeem/AgencyRedeemRequest.js";
import Commission from "../component/table/Commission.js";
import HostRequest from "../component/table/hostRequest/HostRequest.js";


import Profile from "./Profile.js";
import FakeComment from "../component/table/FakeComment.js";
import GameHistory from "../component/table/GameHistory.js";
import Avatar from "../component/table/Avatar.js";
import AdmissionCar from "../component/table/AdmissionCar.js";
import CoinSeller from "../component/table/CoinSeller.js";
import CoinSellerHistory from "../component/table/CoinSellerHistory.js";
import UserRedeemRequest from "../component/table/userRedeem/UserRedeemRequest.js";
import Reaction from "../component/table/Reaction.js";
import { baseURL } from "../util/Config.js";
import { UNSET_ADMIN } from "../store/admin/types.js";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { io } from "socket.io-client";
import { Toast } from "../util/Toast.js";
import PendingRequestTable from "../component/table/profileRequest/PendingRequest.js";
import AcceptedRequestTable from "../component/table/profileRequest/AcceptedRequest.js";
import DeclineRequestTable from "../component/table/profileRequest/DeclineRequest.js";

const Admin = () => {
  const location = useRouteMatch();
  const history = useHistory();
  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const ipAddress = localStorage.getItem("ipAddress");

  useEffect(() => {
    if (
      history.location?.pathname === "/admin" ||
      history.location?.pathname === "/" ||
      history.location?.pathname === ""
    ) {
      history.push("/admin/dashboard");
    }
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${baseURL}admin/checkIpAdress`)
  //     .then((response) => {
  //       if (ipAddress !== "") {
  //         if (response?.data?.data !== ipAddress) {
  //           dispatch({ type: UNSET_ADMIN });
  //         }
  //       }
  //     })
  //     .catch((error) => console.error("Error:", error));
  // }, []);

  // useEffect(() => {
  //   if (ipAddress.length > 0) {
  //     const socket = io.connect(baseURL, {
  //       transports: ["websocket", "polling", "flashsocket"],
  //       query: {
  //         adminRoom: admin && admin?._id,
  //       },
  //     });
  //     socketRef.current = socket ? socket : null;
  //     socketRef.current.on("connect", () => {
  //       console.log("Socket connected");
  //       // socketRef.current.emit("ticket", ipAddress && ipAddress);
  //     });
  //     socketRef.current.on("checkIpAdress", (data) => {
  //       console.log("data",data);
  //       if (data !== ipAddress) {
  //         dispatch({ type: UNSET_ADMIN });
  //       }
  //     });
  //     socketRef.current.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });
  //     socketRef.current.on("connect_error", (error) => {
  //       console.error("Socket connection error:", error);
  //       Toast(error);
  //     });
  //     return () => {
  //       console.log("Cleaning up socket connection");
  //       socketRef.current.disconnect();
  //     };
  //   }
  // }, [ipAddress]);

  return (
    <>
      <div class="page-container">
        <Navbar />
        <div class="page-content">
          <Topnav />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location?.path}/dashboard`}
                exact
                component={Dashboard}
              />
              <Route
                path={`${location?.path}/profile`}
                exact
                component={Profile}
              />
              <Route
                path={`${location?.path}/banner`}
                exact
                component={BannerTable}
              />
              <Route
                path={`${location?.path}/coinplan`}
                exact
                component={CoinPlanTable}
              />
              <Route
                path={`${location?.path}/coinplan/history`}
                exact
                component={PurchaseCoinPlanHistoryTable}
              />
              <Route
                path={`${location?.path}/vipplan`}
                exact
                component={VIPPlanTable}
              />
              <Route
                path={`${location?.path}/vipplan/history`}
                exact
                component={PurchaseVIPPlanTable}
              />
              <Route
                path={`${location?.path}/giftCategory`}
                exact
                component={GiftCategoryTable}
              />
              <Route
                path={`${location?.path}/gameHistory`}
                exact
                component={GameHistory}
              />
              <Route
                path={`${location?.path}/theme`}
                exact
                component={ThemeTable}
              />
              <Route
                path={`${location?.path}/giftCategory/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location?.path}/giftCategory/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location?.path}/gift`}
                exact
                component={GiftTable}
              />
              <Route
                path={`${location?.path}/gift/dialog`}
                exact
                component={GiftDialog}
              />
              <Route
                path={`${location?.path}/song`}
                exact
                component={SongTable}
              />
              <Route
                path={`${location?.path}/song/dialog`}
                exact
                component={SongDialog}
              />
              <Route
                path={`${location?.path}/hashtag`}
                exact
                component={HashtagTable}
              />
              <Route
                path={`${location?.path}/level`}
                exact
                component={LevelTable}
              />
              <Route
                path={`${location?.path}/user`}
                exact
                component={UserTable}
              />

              <Route
                path={`${location?.path}/user/detail`}
                exact
                component={UserDetail}
              />
              <Route
                path={`${location?.path}/user/history`}
                exact
                component={UserHistory}
              />

              <Route
                path={`${location?.path}/liveUser`}
                exact
                component={LiveUserTable}
              />

              <Route
                path={`${location?.path}/post`}
                exact
                component={PostTable}
              />

              <Route
                path={`${location?.path}/post/detail`}
                exact
                component={PostDetail}
              />

              <Route
                path={`${location?.path}/video`}
                exact
                component={VideoTable}
              />

              <Route
                path={`${location?.path}/setting`}
                exact
                component={Setting}
              />
              <Route
                path={`${location?.path}/video/detail`}
                exact
                component={VideoDetail}
              />
              <Route
                path={`${location?.path}/reportedUser`}
                exact
                component={ReportedUserTable}
              />
              <Route
                path={`${location?.path}/advertisement`}
                exact
                component={Advertisement}
              />
              <Route
                path={`${location?.path}/pendingComplain`}
                exact
                component={PendingComplainTable}
              />
              <Route
                path={`${location?.path}/solvedComplain`}
                exact
                component={SolvedComplainTable}
              />
              <Route
                path={`${location?.path}/userRedeemRequest`}
                exact
                component={UserRedeemRequest}
              />

            
              <Route
                path={`${location?.path}/sticker`}
                exact
                component={StickerTable}
              />

              <Route
                path={`${location?.path}/comment`}
                exact
                component={FakeComment}
              />
              <Route
                path={`${location?.path}/entryEffect`}
                exact
                component={AdmissionCar}
              />

              <Route
                path={`${location?.path}/avatarFrame`}
                exact
                component={Avatar}
              />
              <Route
                path={`${location?.path}/game`}
                exact
                component={GameTable}
              />

              <Route
                path={`${location?.path}/coinSeller`}
                exact
                component={CoinSeller}
              />
              <Route
                path={`${location?.path}/coinSeller/history`}
                exact
                component={CoinSellerHistory}
              />
              <Route
                path={`${location?.path}/agencyRedeemRequest`}
                exact
                component={AgencyRedeemRequest}
              />
              <Route
                path={`${location?.path}/agency`}
                exact
                component={AgencyTable}
              />
              <Route
                path={`${location?.path}/agency/agencyWiseUsers`}
                exact
                component={AgencyWiseHost}
              />
              <Route
                path={`${location?.path}/commission`}
                exact
                component={Commission}
              />
              <Route
                path={`${location?.path}/hostRequest`}
                exact
                component={HostRequest}
              />
              <Route
                path={`${location?.path}/reaction`}
                exact
                component={Reaction}
              />
              <Route
                path={`${location?.path}/pendingRequest`}
                exact
                component={PendingRequestTable}
              />
              <Route
                path={`${location?.path}/AcceptedRequest`}
                exact
                component={AcceptedRequestTable}
              />
              <Route
                path={`${location?.path}/declineRequest`}
                exact
                component={DeclineRequestTable}
              />
            </Switch>
            {/* <Spinner /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;
