/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getReaction, deleteReaction } from "../../store/reaction/action";

//config
import { baseURL } from "../../util/Config";
//routing
import { Link, useHistory } from "react-router-dom";

import { warning } from "../../util/Alert";

//image
import noImage from "../../assets/images/noImage.png";

import { OPEN_REACTION_DIALOG } from "../../store/reaction/type";
import ReactionDialog from "../dialog/ReactionDialog";
import { io } from "socket.io-client";
import { UNSET_ADMIN } from "../../store/admin/types";

const ReactionTable = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getReaction());
  }, [dispatch]);

  const { reaction } = useSelector((state) => state.reaction);

  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const ipAddress = localStorage.getItem("ipAddress");


  // useEffect(() => {
  //   if (ipAddress.length > 0) {
  //     const socket = io.connect(baseURL, {
  //       transports: ["websocket", "polling", "flashsocket"],
  //       query: {
  //         adminRoom: admin && admin?._id,
  //       },
  //     });
  //     socketRef.current = socket ? socket : null;
  //     socketRef.current.on("connect", () => {
  //       console.log("Socket connected");
  //       // socketRef.current.emit("ticket", ipAddress && ipAddress);
  //     });
  //     socketRef.current.on("checkIpAdress", (data) => {
  //       console.log("data",data);
  //       if (data !== ipAddress) {
  //         dispatch({ type: UNSET_ADMIN });
  //       }
  //     });
  //     socketRef.current.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });
  //     socketRef.current.on("connect_error", (error) => {
  //       console.error("Socket connection error:", error);
  //     });
  //     return () => {
  //       console.log("Cleaning up socket connection");
  //       socketRef.current.disconnect();
  //     };
  //   }
  // }, [ipAddress]);

  useEffect(() => {
    setData(reaction);
  }, [reaction]);

  const handleDelete = (reactionId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteReaction(reactionId);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_REACTION_DIALOG, payload: data });
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_REACTION_DIALOG });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Reactions</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>

                <li className="breadcrumb-item active" aria-current="page">
                  Reaction
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="main-wrapper">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
            <div class="mb-5">
              <button
                className="btn btn-danger btn-sm float-left "
                type="button"
                onClick={handleOpen}
              >
                New
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          {data?.length > 0 ? (
            data?.map((data, index) => {
              return (
                <>
                  <div
                    class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3"
                    key={index}
                  >
                    <div class="card contact-card card-bg">
                      <div class="card-body p-1">
                        <div className="row px-3 py-4">
                          <div className="col-4 ps-4">
                            <img
                              src={data?.image ? data?.image : noImage}
                              style={{
                                width: "135px",
                                height: "135px",
                                objectFit: "cover",
                              }}
                              alt=""
                              className="rounded-circle my-auto"
                              height={80}
                            />
                          </div>
                          <div
                            className="col-8 pe-4 text-end"
                            style={{
                              padding: 0,
                              paddingLeft: 5,
                            }}
                          >
                            <div className="px-3">
                              {/* <i
                                class="fas fa-edit text-white p-2 bg-primary rounded-circle"
                                style={{ marginRight: 10, fontSize: 25 }}
                                onClick={() => handleEdit(data)}
                              ></i> */}

                              <i
                                class="fas fa-trash text-white p-2 bg-danger rounded-circle"
                                style={{ marginRight: 10, fontSize: 25,cursor:"pointer" }}
                                onClick={() => handleDelete(data._id)}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <tr>
              <td colSpan="6" align="center">
                Nothing to show!!
              </td>
            </tr>
          )}
        </div>
      </div>
      <ReactionDialog />
    </>
  );
};

export default connect(null, { getReaction, deleteReaction })(ReactionTable);
