import React, { Suspense, useEffect, useRef, useState } from "react";

// routing
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
//types
import { SET_ADMIN, UNSET_ADMIN } from "./store/admin/types";

import { IdleTimeoutManager } from "idle-timer-manager";

//Components
import Login from "./pages/LoginPage";
import UnlockScreenPage from "./pages/UnlockScreenPage";
import Page404 from "./pages/Page404";
import Admin from "./pages/Admin";
import AuthRouter from "./util/AuthRouter";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Spinner from "./pages/Spinner";
import AuthRouter2 from "./util/AuthRouter2";
import AgencyMain from "./pages/AgencyPanel/AgencyMain";
import AgencyLogin from "./pages/AgencyLogin";

function App() {
  const dispatch = useDispatch();

  const token = localStorage.getItem("TOKEN");
  const [ipAddress, setIpAddress] = useState("");

  const { isAuth, admin } = useSelector((state) => state.admin);

  const isAuth2 = localStorage.getItem("isAuth2");

  const key = localStorage.getItem("KEY");
  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_ADMIN, payload: token });
  }, [token, key, dispatch]);

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //30 min (in sec)
      onExpired: (time) => {
        dispatch({ type: UNSET_ADMIN });
        return (window.location.href = "/");
      },
    });

    return () => {
      manager?.clear();
    }; //eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   fetch("https://ipapi.co/json/", {
  //     method: "GET",
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
        
  //       setIpAddress(data?.network);
  //       localStorage.setItem("ipAddress", data?.network);
  //     })
  //     .catch((error) => console.error("Error:", error));
  // }, []);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Switch>
            <AuthRouter exact path="/" component={Login} />
            <AuthRouter exact path="/login" component={Login} />
            <AuthRouter2 exact path="/agencyPanel" component={AgencyLogin} />
            <AuthRouter exact path="/unlock" component={UnlockScreenPage} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route
              exact
              path="/changePassword/:id"
              component={ChangePassword}
            />
            {isAuth && <Route path="/admin" component={Admin} />}

            {isAuth2 && <Route path="/agencyPanel" component={AgencyMain} />}
            <Route component={Page404} />
          </Switch>
          <Spinner />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
